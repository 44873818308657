const _ = require('lodash')
const loading = require('../gen/init/loading')
const {getLanguageCode} = require('./init/multilingual')
const {webBiLoggerFactory} = require('./init/loggerFactory')
const supportedLanguages = require('../gen/supportedLanguages')
const {createFunctionLibrary} = require('./init/initFunctionLibrary')
const storageFactory = require('./init/utils/storageFactory')
const {getRuntimeStoreDefaultValue, getRuntimeEventsStoreDefaultValue} = require('./init/utils/runtimeStoreUtils')
const {applyGradualRolloutOverrides, overrideClientSpecMapScriptsFromQuery, removeApps} = require('./init/utils/clientSpecMapUtils')

const {updateHistory} = require('./init/functionLibrary/navigationLib')
const {initSeoWrapper} = require('./aspects/seo/seoComponentsWrapperFactory')
const noop = () => {}

const getMasterPage = components => ({[components.MasterPage.compType]: components.MasterPage})

const getBoltComponentsMap = boltComponents => Object.values(boltComponents)
    .filter(comp => comp.compType)
    .reduce((acc, compClass) => ({
        [compClass.compType]: compClass,
        ...acc
    }), {})

const getCompClasses = (compClasses, {components}, boltComponents) => {
    const compTypesToClasses = compClasses || getMasterPage(components) //santa-components are registered through selectiveDownload, but we need masterPage early
    const overrides = {
        [components.ThemeRendererWithFonts.compType]: components.ThemeRendererWithFonts,
        ...getBoltComponentsMap(boltComponents)
    }
    return {...compTypesToClasses, ...overrides, div: 'div'}
}

function createInitialTranslations(translations) {
    return supportedLanguages.reduce((acc, languageCode) => {
        const languageTranslation = translations[languageCode]
        const emptyMap = {data: {document_data: {}}}
        return {...acc, [languageCode]: languageTranslation || emptyMap}
    }, {})
}

function isStorageSupported() {
    try {
        window.localStorage.setItem('', '')
        window.localStorage.removeItem('')
        return true
    } catch (exception) {
        return false
    }
}

const createCommonConfig = publicModel => {
    if (!publicModel) { // editor mode
        return window.commonConfig
    }

    // TODO move this calculation to santa-site-renderer
    const brand = _.chain(publicModel).get('metaSiteFlags', {}).includes('EditorX').value() ? 'editorx' : 'wix'

    return {
        brand
    }
}
// TODO group all these inputs into meaningful sub models
/*eslint fp/no-mutation:0*/
const init = ({logger, sentry, hostInstanceBatchingStrategy, boltInstanceBatchingStrategy, functionLibrary, renderingPlugins, ssrModel, rendererModel, publicModel, documentServicesModel, serviceTopology, requestModel, rawSeoTags, tpaSeoInfoServiceUrl, analyticsTrackers, rawUrl, wixBiSession, isPreview, isDS, reportBeatEvent, registerToIframesLoadEvent, isBot, isDebug, isQA, santaBase, boltBase, compClasses, bootstrapPackages, renderFlags, navigationModel}) => {
    const {fetch, fetchWixappsDataFromDM, flattenStructure, addPageStructureAndData, requireFn, requireSync, getPackageJsonVersion, reportActionStart, reportActionEnd, requireTPANativeCode, getQueryParamValue, parseQueryParams, stringifyQuery, isHttps, prefersReducedMotion} = functionLibrary
    const {isStreaming, isInSSR, boltInstanceFunctionLibrary = {}, exceptionHandlingApi} = ssrModel
    const initCommonConfig = createCommonConfig(publicModel)
    if (!isInSSR && !window.commonConfig) { // TODO remove after verifying that no component uses the config directly from window (OOI, TPA)
        window.commonConfig = initCommonConfig
    }
    const clientSpecMap = rendererModel.clientSpecMap
    applyGradualRolloutOverrides(clientSpecMap)
    overrideClientSpecMapScriptsFromQuery(rawUrl, serviceTopology, clientSpecMap)
    //I hate this
    const disableApp = getQueryParamValue(rawUrl, 'disableApp')
    const disableDataBinding = disableApp && disableApp.toLowerCase().includes('databinding')
    rendererModel.clientSpecMap = removeApps(clientSpecMap, disableApp)

    // I hate this 2
    const forceResponsive = getQueryParamValue(rawUrl, 'forceResponsive')
    if (forceResponsive === 'true') {
        _.set(rendererModel, ['siteMetaData', 'isResponsive'], true)
    }

    const forceMobileView = getQueryParamValue(rawUrl, 'showMobileView') === 'true'

    const mainRModel = {
        isDS,
        isPreview,
        additionalStructures: {},
        pagesToLoad: [],
        pagesJsonFileName: {},
        pageRawContent: {},
        packages: bootstrapPackages || {},
        loadedComponents: {},
        sitemapQueries: {},
        boltInstance: null,
        rendererModel,
        publicModel,
        commonConfig: initCommonConfig,
        documentServicesModel,
        serviceTopology,
        requestModel,
        navigationModel: _.assign({
            rawUrl,
            prevParsedUrl: null,
            navigationInfos: {},
            pendingDynamicPageInfo: null,
            boltInstanceNavigateCallback: null,
            boltInstanceRetryNavigateCallback: null,
            navigationErrorCallbacks: []
        }, navigationModel),
        pagesLoadingModel: {
            additionalPagesToLoad: {},
            sitePagesVersion: 0
        },
        ssrModel: {
            warmupPackages: {},
            isStreaming,
            ssrSucceeded: false,
            doneWarmup: false,
            serverMarkup: '',
            isInSSR,
            warmupData: null
        },
        platformModel: {
            compsToExcludeFromRMI: ['SITE_PAGES', 'PAGES_CONTAINER', 'masterPage'],
            disableDataBinding,
            platformContextCounter: 1,
            platformLoadContextCounter: 1,
            wixCode: {
                cacheKiller: {}
            },
            ghostStructure: {},
            ghostControllers: {}
        },
        appInstanceMap: {},
        workerBuffers: {},
        animationManager: null,
        warmupAnimationsStarted: false,
        isBot,
        isDebug,
        isQA,
        santaBase,
        boltBase,
        workers: {},
        inEditMode: rendererModel.previewMode,
        pagesDataItemsMap: {},
        workersState: {},
        standbyWorker: null,
        iframeWorkerWrapper: {},
        storage: storageFactory.get(isStorageSupported()),
        wixBiSession,
        forceMobileView,
        viewerLoaded: false
    }

    return new Promise(resolve => {
        const createHostInstanceReportId = reportActionStart('createHostInstance')

        const stageResolvers = {}
        // eslint-disable-next-line promise/param-names
        const doneStagePromise = new Promise((doneStageResolved, doneStageRejected) => {
            stageResolvers.doneStageResolved = doneStageResolved
            stageResolvers.doneStageRejected = doneStageRejected
        })

        const hostInstance = loading(
            mainRModel,
            {
                createBoltInstance: payload => {
                    const {
                        isExperimentOpen,
                        boltMain,
                        santaComponents,
                        boltComponents,
                        initialPages,
                        isMobileView,
                        formFactor,
                        mobileDeviceAnalyzer,
                        hostApi,
                        commonConfig,
                        bsiInstanceWrapper,
                        navigationInfos,
                        currentUrl,
                        viewerModel,
                        platformModel,
                        storage,
                        callback
                    } = payload

                    const pages = _.sortBy(initialPages, v => v.structure.masterPage ? 1 : 0) //see test BOLT-830
                    const initialPageData = _.reduce(pages, _.merge, {
                        structure: {},
                        data: {},
                        translations: {},
                        meshResults: {}
                    })

                    const translations = createInitialTranslations(initialPageData.translations)
                    const {structure, data, meshResults} = initialPageData

                    // remove this line when merge sv_multilingualSubDomains
                    const currentLanguage = isExperimentOpen('sv_multilingualSubDomains') ?
                        _.get(rendererModel, 'sitePropertiesInfo.multilingualInfo.languageCode') :
                        getLanguageCode(getQueryParamValue(rawUrl, 'lang'), rendererModel.sitePropertiesInfo)

                    const boltInitialModel = {
                        isDS,
                        sentry,
                        storage,
                        webBiLoggerFactory,
                        commonConfig: _.cloneDeep(commonConfig),
                        bsiInstanceWrapper,
                        currentLanguage,
                        documentServicesModel,
                        initialClientSpecMap: clientSpecMap,
                        navigationInfos,
                        currentUrl,
                        structure,
                        data,
                        loadedFonts: {},
                        uploadedFonts: {},
                        translations,
                        meshResults,
                        serviceTopology,
                        rendererModel,
                        publicModel,
                        rawSeoTags,
                        tpaSeoInfoServiceUrl,
                        analyticsTrackers,
                        viewerModel,
                        compClasses: getCompClasses(compClasses, santaComponents, boltComponents),
                        activeModes: {},
                        templatesRefs: {},
                        activeVariants: {},
                        runtime: getRuntimeStoreDefaultValue(),
                        runtimeEvents: getRuntimeEventsStoreDefaultValue(),
                        isMobileView,
                        formFactor,
                        forceMobileView,
                        mobileDeviceAnalyzer,
                        wixBiSession,
                        reportBeatEvent,
                        renderFlags,
                        isQA,
                        ssrModel: {
                            isInSSR,
                            isClientAfterSSR: isStreaming,
                            isFirstRenderAfterSSR: false
                        },
                        rootStyleIds: ['CSS_CONTAINER'],
                        rootCompIds: ['BOLT_SITE'],
                        getWindowObject: isInSSR ? noop : () => window,
                        requestModel,
                        santaBase,
                        boltBase,
                        platformModel,
                        viewModeSwitchCount: {count: 0}
                    }

                    const createBoltInstanceReportId = reportActionStart('createBoltInstance')
                    logger.log('creating bolt instance', performance.now())

                    const boltInstance = boltMain.default.createBoltInstance(
                        boltInitialModel,
                        boltInstanceBatchingStrategy,
                        renderingPlugins,
                        hostApi,
                        logger,
                        {
                            fetch,
                            fetchWixappsDataFromDM,
                            flattenStructure,
                            prefersReducedMotion,
                            addPageStructureAndData,
                            isHttps,
                            stringifyQuery,
                            requireFn,
                            getPackageJsonVersion,
                            requireSync,
                            reportActionStart,
                            reportActionEnd,
                            captureError: logger.captureError,
                            interactionStarted: logger.interactionStarted,
                            interactionEnded: logger.interactionEnded,
                            requireTPANativeCode,
                            parseQueryParams,
                            ...boltInstanceFunctionLibrary,
                            ...rendererModel.seo ? {wrapWithHostHOC: initSeoWrapper(boltComponents)} : {}
                        },
                        exceptionHandlingApi
                    )

                    logger.log('created bolt instance', performance.now())
                    reportActionEnd(createBoltInstanceReportId)

                    if (!isInSSR) {
                        window.boltInstance = boltInstance
                        if (navigationInfos.primaryPage.replaceHistory) {
                            updateHistory(navigationInfos, currentUrl.full, currentUrl.protocol)
                        }
                        if (window.location.hash.startsWith('#!') && window.location.hash !== '#!') {
                            logger.captureError(new Error('received hashbang url'), {
                                extras: {
                                    href: window.location.href,
                                    referrer: document.referrer
                                }
                            })
                        }

                        if (sentry.onLoad) {
                            sentry.onLoad(() => {
                                boltInstance.setSentry(window.Sentry)
                            })
                        }
                    }

                    callback(boltInstance)
                    resolve({boltInstance, boltMain: boltMain.default, doneStagePromise})
                },
                done: (boltMain, boltInstance, ssrSucceeded, serverMarkup, warmupUtils, workers) => {
                    if (isInSSR) {
                        Object.values(workers).forEach(worker => worker.terminate())
                    }

                    stageResolvers.doneStageResolved({
                        hostInstance,
                        boltInstance,
                        boltMain: boltMain.default,
                        serverMarkup,
                        hydrate: ssrSucceeded,
                        indicator: warmupUtils.indicator
                    })
                },
                onSsrRouteRedirect: redirectPayload => resolve({redirectPayload}),
                reportBeatEvent,
                registerToIframesLoadEvent,
                ...functionLibrary
            },
            hostInstanceBatchingStrategy
        )
        reportActionEnd(createHostInstanceReportId)

        if (!isInSSR) {
            window.hostInstance = hostInstance
            functionLibrary.requireFn('zepto', $ => {
                $(() => {
                    const {warmupData} = window
                    if (isStreaming && warmupData) {
                        const {
                            // userWarmup,
                            currentUrl,
                            rootNavigationInfo
                        } = window.warmupData
                        hostInstance.setParsedUrl(currentUrl)
                        if (rootNavigationInfo) {
                            hostInstance.setNavigationInfos(Object.assign({primaryPage: rootNavigationInfo}, hostInstance.navigationInfos))
                        }
                        // hostInstance.setUserWarmup(userWarmup)
                        hostInstance.setWarmupData(warmupData)
                    } else {
                        hostInstance.setWarmupData({runtime: 'EMPTY'})
                    }
                })
            })
        }
    })
}

module.exports = {
    init,
    createFunctionLibrary
}
